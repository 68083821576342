export enum PrimaryFocus {
  SUBSTANCE_USE = 'Substance Use',
  MENTAL_HEALTH = 'Mental Health',
  SUBSTANCE_USE_AND_MENTAL_HEALTH = 'Substance Use and Mental Health',
  EATING_DISORDERS = 'Eating Disorders',
  BEHAVIORAL_ADDITION = 'Behavioral Addiction',
  WELLNESS = 'Wellness',
  AUTISM = 'Autism'
}

export type PrimaryFocusType = `${PrimaryFocus}`

export enum CoOccurringDisorder {
  MENTAL_HEALTH = 'Co-Occurring Mental Health',
  SUBSTANCE_USE = 'Co-Occurring Substance Use'
}

export const PRIMARY_FOCUS_SLUG_MAPPING = {
  [PrimaryFocus.MENTAL_HEALTH]: `depression`,
  [PrimaryFocus.EATING_DISORDERS]: `eating-disorders`
}
