module.exports = [{
      plugin: require('../plugins/gatsby-perf-hydrate-starttransition/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KNK34K","defaultDataLayer":{"type":"object","value":{}},"includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://recoverycomresourcelibrary.kinsta.cloud/graphql","html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"__all":{},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":30,"requestConcurrency":1,"previewRequestConcurrency":1,"queryDepth":3,"timeout":500000,"circularQueryLimit":5,"typePrefix":"Wp"},"catchLinks":false,"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"recovery.com","short_name":"recovery.com","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/favicons/favicon.svg","icons":[{"src":"src/images/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicons/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/images/favicons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicons/favicon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"src/images/favicons/favicon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"src/images/favicons/favicon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/images/favicons/favicon-167x167.png","sizes":"167x167","type":"image/png"},{"src":"src/images/favicons/favicon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"src/images/favicons/favicon-196x196.png","sizes":"196x196","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fd9b37d1176b5d3463fd7b6c85e68558"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
