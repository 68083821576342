export const PRESERVED_TERMS_CASE_ACRONYMS = {
  'adhd, add': 'ADHD, ADD',
  geha: 'GEHA',
  dbt: 'DBT',
  'lgbtq+': 'LGBTQ+',
  'eye movement therapy (emdr)': 'EMDR',
  'acceptance and commitment therapy (act)': 'ACT',
  'aa/na meetings': 'AA/NA',
  'cognitive behavioral therapy (cbt)': 'CBT',
  'early intervention for hiv': 'HIV',
  mhn: 'MHN'
}

export const TERMS_WITH_PRECEDING_ARTICLES = [
  'business center',
  'physical therapy',
  'pool',
  'spa'
]

export const TERMS_WITHOUT_PRECEDING_ARTICLES = [
  'excursions',
  'internet',
  'private rooms'
]

export const SEARCHEABLE_FACETS = [
  {
    title: 'Condition',
    name: 'what_we_treat',
    slug: 'condition',
    limit: 10
  },
  {
    title: 'Clientele',
    name: 'who_we_treat',
    slug: 'clientele',
    limit: 10
  },
  {
    title: 'Approach',
    name: 'treatment_methods',
    slug: 'approach',
    limit: 10
  },
  {
    title: 'Accepted Insurance',
    name: 'accepted_insurance',
    slug: 'insurance',
    limit: 10
  },
  {
    title: 'Treatment Therapies',
    name: 'treatment_therapies',
    slug: 'therapies',
    limit: 10
  },
  {
    title: 'Treatment Services',
    name: 'treatment_services',
    slug: 'care',
    limit: 10
  },
  {
    title: 'Amenities',
    name: 'amenities',
    slug: 'amenities',
    limit: 10
  },
  {
    title: 'Off-Site Activity',
    name: 'off_site_activities',
    slug: 'activities',
    limit: 10
  },
  {
    title: 'Activity',
    name: 'onsite_activities',
    slug: 'activities',
    limit: 10
  },
  {
    title: 'Setting',
    name: 'setting',
    slug: 'setting',
    limit: 10
  },
  {
    title: 'Special Considerations',
    name: 'special_considerations',
    limit: 10
  },
  {
    title: 'Language',
    name: 'languages',
    limit: 10
  }
]

export const TAXONOMY_ROUTES = {
  accepted_insurance: 'insurance',
  what_we_treat: 'condition',
  who_we_treat: 'clientele',
  treatment_therapy: 'therapies',
  treatment_services: 'care',
  amenities: 'amenities',
  activity: 'activities',
  setting: 'setting',
  price: 'price',
  treatment_method: 'approach',
  insurance_accepted: 'insurance',
  'Location+accepted_insurance': 'insurance',
  'Location+special_considerations': 'amenities',
  'Location+what_we_treat': 'condition',
  'Location+who_we_treat': 'clientele',
  'Location+treatment_options': 'approach',
  'Location+treatment_therapies': 'therapies',
  'Location+treatment_services': 'care',
  'Location+amenities': 'amenities',
  'Location+activities': 'activities',
  'Location+setting': 'setting',
  'Location+price': 'price',
  'Location+treatment_methods': 'approach',
  'Location+insurance_accepted': 'insurance'
}

export const TAXONOMY_FAQ_PAGE_TYPES = [
  'what_we_treat',
  'who_we_treat',
  'treatment_method',
  'accepted_insurance',
  'treatment_therapy',
  'treatment_services',
  'amenities',
  'setting',
  'special_considerations'
]
