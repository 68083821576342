import sanitizeHtml from 'sanitize-html'
import { isNonEmptyString, isString } from './checks'
import {
  TERMS_WITH_PRECEDING_ARTICLES,
  TERMS_WITHOUT_PRECEDING_ARTICLES
} from '../domain/term/constants'

const AVERAGE_WORDS_PER_MINUTE = 300
const ARTICLE_REGEX = /\w+/g

export const addArticleTo = (word: string): string => {
  if (!isNonEmptyString(word)) {
    return 'a'
  }

  const vowels = ['a', 'e', 'i', 'o', 'u']
  const firstLetter = word.trim().charAt(0).toLowerCase()

  return vowels.includes(firstLetter) ? 'an ' + word : 'a ' + word
}

export const getPrecedingArticle = (title: string): string => {
  if (TERMS_WITH_PRECEDING_ARTICLES.includes(title.toLowerCase())) {
    return 'a'
  }
  if (TERMS_WITHOUT_PRECEDING_ARTICLES.includes(title.toLowerCase())) {
    return ''
  }
  return 'a'
}

export const removeByRegex =
  (regex: RegExp) =>
  (str: string): string => {
    if (isString(str)) {
      return str.replace(regex, '')
    }
    return ''
  }

export const stripNonNumeric = removeByRegex(/[^0-9]+/g)
export const stripSlash = removeByRegex(/\//g)

export const estimateMinutes = (str: string): number => {
  const wordCount = getWordCount(removeTagsAndNewLines(str))
  return Math.ceil(wordCount / AVERAGE_WORDS_PER_MINUTE)
}

export const getWordCount = (string: string): number =>
  string.match(ARTICLE_REGEX)?.length ?? 0

const removeTagsAndNewLines = (str) => {
  if (!str) return ''
  return sanitizeHtml(str, { allowedTags: [] })
    .replace(/\n/g, '')
    .replace(/&amp;/gm, '&')
    .replace(/&nbsp;/gm, ' ')
    .replace(/&quot;/gm, '"')
    .replace(/&apos;/gm, "'")
    .replace(/&lt;/gm, '<')
    .replace(/&gt;/gm, '>')
}
